<template>
    <div class="">

        <div>
      <b-navbar toggleable="lg" type="light">
        <b-container>
          <b-navbar-brand to="/login"
            ><div class="d-flex align-items-center">
            <img
              src="@/assets/images/pages/MyPals_Logo_Dark.svg"
              width="auto"
              height="44"
              alt="MyPals"
          />
          <!-- <h3 class="mb-0 ml-2 text-primary logo-title-red">MyPals</h3> -->
             </div></b-navbar-brand>

          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

          <b-collapse id="nav-collapse" is-nav>
            <!-- Right aligned nav items -->
            <b-navbar-nav class="ml-auto">
              <b-nav-item class="mr-2" to="/login">Home</b-nav-item>
              <!-- <b-nav-item class="mr-2" to="/howitworks"
                >How It Works</b-nav-item
              > -->


           <!--    <b-button
                class="login-button"
                variant="primary"
                href="javascript:void(0);"
                @click="showLogin"
                data-toggle="modal"
                data-target="#loginModal"
                >Log In</b-button
              >  -->
              
            </b-navbar-nav>
          </b-collapse>
        </b-container>
      </b-navbar>
    </div>

        <!-- <section class="privacy-policy bg-white">
        <div class="container">
            <div class="row justify-content-center text-center">
          <div class="col-lg-12 section-heading">
            <div class="legal-tabs">
            <b-nav tabs>
            <b-nav-item active to="/privacy-policy">Privacy Policy</b-nav-item>
            <b-nav-item  to="/user-agreement">User Agreement</b-nav-item>
            <b-nav-item to="/eula">EULA</b-nav-item>
            </b-nav>
            </div>
          </div>
        </div>
        </div>
        </section> -->

        <section class="privacy-policy bg-white">
        <div class="container">
            <div class="row ">
          <div class="col-lg-12 section-heading">
            <div class="legal-tabs">
            <b-tabs content-class="">
            <b-tab title="Privacy Policy" active>
            <privacy-policyv2 />
            </b-tab>

            <b-tab title="User Agreement">
            <user-agreement />
            </b-tab>

            <b-tab title="EULA">
            <EULA />
            </b-tab>
            </b-tabs>
            </div>
          </div>
        </div>
        </div>
        </section>

        <footer class="footer-pages border-top">
      <div class="container">
        <div class="row" style="padding-bottom: 64px">
          <div class="col-lg-5 mb-5 mb-md-0 mb-lg-0">
            <div class="footer-info">
              <div class="d-flex align-items-center">
            <img
              src="@/assets/images/pages/MyPals_Logo_Dark.svg"
              width="auto"
              height="44"
              alt="MyPals"
          />
          <!-- <h3 class="mb-0 ml-2 text-primary logo-title-red">MyPals</h3> -->
             </div>
              <p class="mt-4">
               MyPals is a social media to exchange digital arts by connecting creators and buyers together.
              </p>
            </div>
          </div>
          <div class="col-lg-3 mb-5 mb-md-0 mb-lg-0">
            <div class="footer-links">
              <h5>Helpful Links</h5>
              <ul class="list-unstyled">
                <li class="d-flex mb-2">
                  <span class="material-icons">chevron_right</span>
                  <router-link to="/login">
                    <a class="" href="#">Home</a>
                  </router-link>
                </li>
                
                <li class="d-flex mb-2">
                  <span class="material-icons">chevron_right</span>
                  <a class="" href="/howitworks">How It Works</a>
                </li>

        
                

                <li class="d-flex mb-2">
                    <span class="material-icons">chevron_right</span>
                <a class="" href="/legal">Legal</a>
                </li>
                <!-- <li class="d-flex">
                    <span class="material-icons">chevron_right</span>
                <a class="" href="#">Contact us</a>
                </li> -->
              </ul>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="footer-contact">
              <h5>Contact Us</h5>
             <!--     <span class="mb-2">Phone: +61 0424910822</span>    -->
              <p>
                Email:
                <a class="" href="mailto:hello@mypals.app"
                  >hello@mypals.app</a
                >
              </p>
             <!-- <ul class="list-inline social-media">
                <li class="list-inline-item mr-3">
                  <a class="" href="https://www.facebook.com/coursepal"
                    ><i class="fa fa-facebook" aria-hidden="true"></i
                  ></a>
                </li>
               <li class="list-inline-item mr-3">
                            <a class="" href="https://coursepal.com.au/index.php/linkedin.com/company/coursepal"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                         </li> 
                <li class="list-inline-item">
                  <a class="" href="https://wa.me/61424910822" target="_blank"
                    ><i class="fa fa-whatsapp" aria-hidden="true"></i
                  ></a>
                </li>
              </ul>  -->
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12 text-center border-top">
            <div class="copyright-pages">
              <span class="text-small"
                >© 2023 MyPals. All Rights Reserved. Powered by
                <a href="#">BRiKS Technology Inc. </a></span
              >
            </div>
          </div>
        </div>
      </div>
    </footer>

    </div>
</template>

<script>
import PrivacyPolicyv2 from './PrivacyPolicyv2.vue'
import UserAgreement from './UserAgreement.vue'
import EULA from './EULA.vue'

export default {
  components: {
    PrivacyPolicyv2,
    UserAgreement,
    EULA
  },

}
</script>



<style scoped lang="scss">
.legal-tabs .nav-tabs .nav-link.active{
    border-color: #fff #fff #fff #dc123e !important;
}
</style>